
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            products: [
                {
                    title: 'Website',
                    description: '',
                    icon: 'mdi-package',
                    list: [
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor'
                    ],
                    attrs: {
                        flat: true,
                        dark: false,
                        outlined: true,
                        style: { 'border': '1px solid #37474F' },
                        class: 'blue-grey--text',
                        secondaryColor: 'blue-grey'
                    }
                },
                {
                    title: 'Store',
                    description: '',
                    icon: 'mdi-storefront',
                    headliner: 'Most Popular',
                    list: [
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor'
                    ],
                    attrs: {
                        elevation: 8,
                        dark: true,
                        color: 'blue-grey darken-3',
                        class: 'white--text',
                        secondaryColor: 'white'
                    }
                },
                {
                    title: 'Custom',
                    description: '',
                    icon: 'mdi-code-braces-box',
                    list: [
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor',
                        'Lorem ipsum dolor'
                    ],
                    attrs: {
                        flat: false,
                        dark: false,
                        outlined: true,
                        style: { 'border': '1px solid #37474F' },
                        class: 'blue-grey--text',
                        secondaryColor: 'blue-grey'
                    }
                }
            ]
        }
    }
})
